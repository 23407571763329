<template>
  <ion-app :class="[{'scanner-hide': gmao.scanActive}, theme]">
    <ion-router-outlet />
  </ion-app>
</template>

<script>
// TODO: Revisar el Plugin @capacitor/geolocation y poner los permisos en AndroidManifest.xml (lo pongo en App.vue porque no se me ocurre otro lado)
import {
  IonApp,
  IonRouterOutlet,
  toastController,
  getPlatforms
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useGmaoStore } from '@/stores/gmao';
import { registerPlugin, Capacitor } from "@capacitor/core";
const BackgroundGeolocation = registerPlugin("BackgroundGeolocation");


export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },

  setup() {
    const gmao = useGmaoStore();
    const platforms = getPlatforms();
    gmao.isMobile = platforms.includes('mobile') || platforms.includes('mobileweb');

    return {
      gmao
    };
  },
  computed: {
    theme() {
      return `preset-${this.gmao.comportamientos?.color_gmao || 1}`;
    }
  },

  created() {
    if(this.$route.meta?.requiresAuth && !this?.gmao?.user?.id) {
      this.$router.replace({ name: 'login'});
    }

    // AXIOS INSTANCE
    this.openToastGeo();
    this.$axios.defaults.baseURL = this.gmao.workspace.api;

    this.$axios.interceptors.response.use((response) => {
      if(response.data?.error === 401) {
        this.$router.replace({ name: 'login' });
        this.openToastOptions();
      }

      return response;
    });

    // GEOLOCATION
    // https://github.com/capacitor-community/background-geolocation

    if (Capacitor.isPluginAvailable('BackgroundGeolocation')) {
      BackgroundGeolocation.addWatcher({
          backgroundTitle: this.$t('geolocalizacion-activada'),
          backgroundMessage: this.$t('usamos-la-geolocalizacion-continua-para-comprobar-las-rutas'),
          requestPermissions: true,
          stale: false,
          distanceFilter: 20
      }, (location, error) => {
        if (error) {
            if (error.code === "NOT_AUTHORIZED") {
                if (window.confirm(
                  this.$t('alert-geolocalizacion')
                )) {
                    BackgroundGeolocation.openSettings();
                }
            }
        }

        if(this.gmao.user?.id) {
          this.$axios.post(`/v2/users/actions.php?call=setTecnicoLocation&token=${this.gmao.user.token}&v2=1`, {
            id_tecnico: this.gmao.user.id,
            latitude: location.latitude,
            longitude: location.longitude,
            altitude: location.altitude,
            accuracy: location.accuracy,
            speed: location.speed,
            time: location.time,
            platform: getPlatforms()[0],
          }, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          });
        }
      }).then(() => {
          // BackgroundGeolocation.removeWatcher({
          //     id: watcher_id
          // });
      });
    }
  },

  methods: {
    async openToastOptions() {
      const toast = await toastController
        .create({
          header: this.$t('la-sesion-ha-caducado'),
          message: this.$t('por-favor-vuelve-a-iniciar-sesion-para-continuar'),
          position: 'top',
          color: 'danger',
          duration: '4000',
          buttons: [
            {
              text: this.$t('Cerrar'),
              role: 'cancel'
            }
          ]
        })

      await toast.present();
    },

    async openToastGeo() {
      const toast = await toastController
        .create({
          header: this.$t('informacion'),
          message: this.$t('puede-que-la-aplicacion-use-la-geolocalizacion'),
          position: 'bottom',
          color: 'secondary',
          duration: '4000',
          buttons: [
            {
              text: this.$t('ok'),
              role: 'cancel',
            }
          ]
        })

      await toast.present();
  }

  }
});
</script>