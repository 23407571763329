import { library } from "@fortawesome/fontawesome-svg-core";
import { faDesktop, fas } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import FontAwesomeIcon from "@/lib/FontAwesomeIcon.vue";

library.add(fas, faDesktop, faTwitter, faInstagram);

// EXAMPLE: Se quita el fa del nombre del icono
//         <fa icon="desktop"></fa>

export { FontAwesomeIcon };